export default {
    namespaced: true,
    state: () => ({
        DanhSachVe: [],
        VeDangChon: [],
    }),

    mutations: {
        Set(state, { key, data }) {
            state[key] = data;
        },
        Unset(state, { key }) {
            delete state[key];
        },
    },
    getters: {},

    actions: {
        // async LayChiTiet({ commit },data) {
        //     let rs = await this.$Core.Api.BaoCao(
        //         this.$i18n.global.t("urlBaoCao.LayChiTiet")
        //     ).Post(data);

        //     if (rs.StatusCode == 200) {
        //         // rs.Data.data.danhSachThongTinTruyenTai.forEach((e)=>{
        //         //     e.STT = ++STT

        //         // })
        //         commit("ChiTietBaoCaoTruyenTaiDuLieu", rs.Data.data);
        //     }
        //     return rs;
        // },
        async TimKiemTheoMaTraCuu(store, data) {
            try {
                let rs = await this.$Core.Api.TraCuu(
                    this.$i18n.global.t("urlTraCuu.TraCuuMaTraCuu"),
                    data
                ).Get();
                if (rs.Data.status) {
                        set(store, "DanhSachVe", [rs.Data.data] || []);
                    if (rs.Data.data) {
                        set(store, "VeDangChon", [rs.Data.data]);
                    }
                } else {
                    set(store, "DanhSachVe", []);
                }
                return rs.Data;
            } catch (error) {
                console.log("🚀 ~ error", error);
            }
        },
        async TimKiemTheoSoDienThoai(store, data) {
            try {
                let rs = await this.$Core.Api.TraCuu(
                    this.$i18n.global.t("urlTraCuu.TraCuuTheoSoDienThoai"),
                    data
                ).Get();
                if (rs.Data.status) {
                    set(store, "DanhSachVe", rs.Data.data || []);
                    if (rs.Data.data.length > 0) {
                        set(store, "VeDangChon", [rs.Data.data[0]]);
                    }
                } else {
                    set(store, "DanhSachVe", []);
                }
                return rs.Data;
            } catch (error) {
                console.log("🚀 ~ error", error);
            }
        },
        ResetDanhSachVe(store) {
            ResetDanhSachVe(store);
        },
    },
};

function set(store, key, data) {
    store.commit("Set", {
        key: key,
        data: data,
    });
}
function ResetDanhSachVe(store) {
    store.commit("Set", {
        key: "DanhSachVe",
        data: [],
    });
}
// function unset(store, key) {
//     store.commit("Unset", {
//         key: key
//     });
// }
