export default {
    namespaced: true,
    state: () => ({
        // : false, true ; false width < 720
        screenWidth: 0,
    }),
    getters: {
        manHinhPC(state) {
            let checkManHinhPC = state.screenWidth < 720 ? false : true;
            return checkManHinhPC;
        },
    },
    mutations: {
        Set(state, { key, data }) {
            state[key] = data;
        },
        Unset(state, { key }) {
            delete state[key];
        },
    },
    actions: {},
};
