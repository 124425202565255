import { createRouter, createWebHistory } from "vue-router";
import none from "./layouts/none";
import Home from "./views/home";
import menuApplication from "./layouts/menu-application";
import TraCuu from "./views/Tra-Cuu/index";
// function loadView(view) {
//     return () => import(`./views/${view}.vue`);
// }
function loadViewIndex(view) {
    return () => import(`./views/${view}/index.vue`);
}

export default function($Core) {
    const router = new createRouter({
        routes: [
            {
                path: "/",
                name: "home",
                meta: {
                    requiresAuth: true,
                    layout: none
                },
                component: TraCuu
            },
            {
                path: "/",
                redirect: "/home"
            },
            {
                path: "/recovery",
                redirect: "/home"
            },
            {
                path: "/:pathMatch(.*)*",
                redirect: "/home"
            }
        ],
        history: createWebHistory()
    });

    router.beforeEach((to, from, next) => {
   
            next();
        
    });
    return router;
}
